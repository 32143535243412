import React, { Component } from "react"
import "./style.scss"

class waButton extends Component {

  render() {
    return <a className="wa-button-wrapper" target="_blank" href="https://api.whatsapp.com/send?phone=6281226843580&text=Halo%20SKM" rel="noopener noreferrer">Hubungi WA</a>
  }
}

export default waButton
